<template>
  <div>
    <b-overlay
      :show="show"
    >
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col :cols="editable ? 9 : 12">
              <b-card>
                <b-row>
                  <b-col
                    cols="12"
                    sm="6"
                  >
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Brigada"
                        rules="required"
                      >
                        <h6 class="text-primary">
                          Brigada
                        </h6>
                        <v-select
                          v-model="conservacionGateway.brigada"
                          :state="errors.length > 0 ? false:null"
                          label="nombre"
                          :options="usuarios"
                          disabled
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    sm="6"
                  >
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Gateway"
                        rules="required"
                      >
                        <h6 class="text-primary">
                          Gateway
                        </h6>
                        <v-select
                          v-model="conservacionGateway.gateway"
                          :state="errors.length > 0 ? false:null"
                          label="nombre"
                          disabled
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-if="!editable">
                  <b-col
                    cols="12"
                    sm="12"
                  >
                    <h6 class="text-primary">
                      Fotografía Panorámica de Antes
                    </h6>
                    <imagen
                      :key="'foto-panoramica-antes'"
                      :leyenda="'foto-panoramica-antes'"
                      @cargar-file="fotografiaPanoramicaAntes"
                    />
                  </b-col>
                </b-row>
                <h2 class="text-primary">
                  Trabajos Realizados
                  <hr>
                </h2>
                <b-row>
                  <b-col
                    cols="12"
                    sm="12"
                  >
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Trabajos Realizados"
                        rules="required"
                      >
                        <h6 class="text-primary">
                          Trabajos Realizados
                        </h6>
                        <v-select
                          v-model="conservacionGateway.trabajosGateway"
                          label="nombre"
                          :state="errors.length > 0 ? false:null"
                          :options="trabajosGateway"
                          multiple
                          @input="handleTrabajosGatewayChange"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-if="conservacionGateway.trabajosGateway && conservacionGateway.trabajosGateway.some(item => item.id === 4)">
                  <b-col
                    cols="12"
                    sm="12"
                  >
                    <h6 class="text-primary">
                      Especifique el Reemplazo de Dispositivos
                    </h6>
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Especifique"
                        rules="required"
                      >
                        <b-form-textarea
                          v-model="conservacionGateway.especificacion"
                          :state="errors.length > 0 ? false:null"
                          rows="2"
                          no-resize
                          :disabled="editable"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-if="conservacionGateway.trabajosGateway && conservacionGateway.trabajosGateway.some(item => item.id === 6)">
                  <b-col
                    cols="12"
                    sm="12"
                  >
                    <b-form-group>
                      <h6 class="text-primary">
                        Supresor
                      </h6>
                      <validation-provider
                        #default="{ errors }"
                        name="Supresor"
                        rules="required"
                      >
                        <b-form-input
                          v-model="conservacionGateway.supresor"
                          :state="errors.length > 0 ? false:null"
                          :disabled="editable"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <hr>
                <b-row v-if="!editable">
                  <b-col
                    cols="12"
                    sm="12"
                  >
                    <h6 class="text-primary">
                      Fotografía Panorámica del Después
                    </h6>
                    <imagen
                      :key="'foto-panoramica-depues'"
                      :leyenda="'foto-panoramica-despues'"
                      @cargar-file="fotografiaPanoramicaDespues"
                    />
                  </b-col>
                </b-row>
                <hr>
                <b-row>
                  <!-- BOTONES -->
                  <b-col
                    v-if="editable"
                    cols="12"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mt-2 mr-1"
                      @click="changeEditable"
                    >
                      <feather-icon
                        icon="EditIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">Editar</span>
                    </b-button>
                  </b-col>
                  <b-col
                    v-if="!editable"
                    cols="12"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mt-2 mr-1"
                      @click="validationForm"
                    >
                      <feather-icon
                        icon="SaveIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">{{ getTextButton() }}</span>
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-secondary"
                      type="reset"
                      class="mt-2"
                      @click.prevent="cancel"
                    >
                      <feather-icon
                        icon="SlashIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">Cancelar</span>
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
            <b-col
              v-if="editable && showAdditionalColumn"
              cols="3"
            >
              <b-card style="min-height: 95%">
                <br>
                <h3
                  style="display: flex; justify-content: center;"
                  class="text-primary"
                >
                  Fotografías del Gateway
                </h3>
                <hr>
                <b-carousel
                  id="carousel-caption"
                  controls
                  indicators
                  height="380"
                  :interval="4000"
                >
                  <template v-for="(item, index) in seguimiento">
                    <b-carousel-slide :key="index">
                      <template #img>
                        <img
                          class="d-block img-fluid w-100 img"
                          height="380"
                          :src="item.url"
                          :alt="item.name"
                        >
                      </template>
                    </b-carousel-slide>
                  </template>
                </b-carousel>
              </b-card>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-overlay>
  </div>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BCard, BFormTextarea, BButton, BCarouselSlide, BCarousel, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Imagen from '@/components/Inspecciones/Imagen.vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  getGateway,
  updateCreateGateway,
} from '@/utils/mantenimiento/conservacionGateway/conservacionGatewayUtils'
// eslint-disable-next-line import/no-extraneous-dependencies
import { mensajeError, mensajeInformativo } from '@/utils/mensajes'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getUsersByCompany } from '@/utils/usuarios'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import { saveImagen } from '@/utils/imagenes'
import { getAdminGateway } from '@/utils/administrador/gateway'
import { datosBrigada } from '@/utils/funciones'
import { getlistado } from '@/utils/catalogos'

export default {
  components: {
    BCarouselSlide,
    BCarousel,
    Imagen,
    BRow,
    BCol,
    BForm,
    BCard,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    BFormGroup,
    BFormInput,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      show: false,
      usuario: JSON.parse(localStorage.getItem('userData')),
      usuarios: [],
      conservacionGateway: {
        id: router.currentRoute.params.gateway,
        fechaCreacion: new Date(),
        brigada: null,
        gateway: '',
        trabajosGateway: [],
        supresor: '',
        especificacion: '',
        estado: 'INGRESADA',
        seguimiento: [],
        tracking: [
          {
            estado: 'INGRESADA',
            horaFecha: new Date(),
          },
        ],
        usuarioCreacion: JSON.parse(localStorage.getItem('userData')),
        companyId: JSON.parse(localStorage.getItem('userData')).idCompany,
      },
      fotoPanoramicaAntes: '',
      fotoPanoramicaDespues: '',
      gatewayId: router.currentRoute.params.gateway,
      adminGateway: null,
      gateway: null,
      required,
      trabajosGateway: [],
      seguimiento: [],
      brigada: [],
      localization: null,
      showAdditionalColumn: true,
      editable:
          router.currentRoute.params.action !== 'Edit'
          && router.currentRoute.params.action !== 'Create',
    }
  },

  async created() {
    const gatewayId = this.$route.params.gateway

    if (this.$route.query.action === 'Create') {
      this.adminGateway = await getAdminGateway(gatewayId)
      if (this.adminGateway && this.adminGateway.nombre) {
        this.conservacionGateway.gateway = this.adminGateway
      }
    }
    this.checkAccessMode()
    const { action } = this.$route.query

    if (action !== 'Create') {
      await this.loadGateway()
    }
    this.usuarios = await getUsersByCompany(this.usuario.idCompany)
    this.usuarios.map(usuario => {
      // eslint-disable-next-line no-param-reassign
      usuario.nombre = `${usuario.firstName} ${usuario.firstSurname}`
      return usuario
    })
    this.trabajosGateway = await getlistado(18, 'Trabajos Gateway', false, this.usuario)
    if (this.conservacionGateway.usuarioCreacion) {
      this.datosBrigada()
    }
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        // eslint-disable-next-line no-return-assign
        pos => (
          this.localization = {
            latitude: pos.coords.latitude,
            longitude: pos.coords.longitude,
          }),
        error => {
          console.error('Error Obteniendo Latitud y Longitud!', error)
          this.localization = null
        },
      )
    }
  },
  methods: {
    datosBrigada() {
      if (this.conservacionGateway.usuarioCreacion) {
        this.conservacionGateway.brigada = datosBrigada(
          this.conservacionGateway.usuarioCreacion,
          this.usuarios,
        )
      }
    },
    async loadGateway() {
      const { gateway } = this.$route.params

      if (gateway) {
        this.conservacionGateway = await getGateway(gateway)

        if (!this.conservacionGateway) {
          console.error('Elemento no encontrado')
        } else {
          this.seguimiento = this.conservacionGateway.seguimiento || []
        }
      }
    },
    async guardarGateway() {
      if (!this.fotoPanoramicaAntes || this.fotoPanoramicaAntes === '') {
        mensajeError('Debe Ingresar una Fotografía Panorámica de Antes!')
        return
      }
      if (!this.fotoPanoramicaDespues || this.fotoPanoramicaDespues === '') {
        mensajeError('Debe Ingresar una Fotografía Panorámica de Después!')
        return
      }
      this.show = true

      if (this.localization === null || this.localization === undefined) {
        mensajeError('Error obteniendo geolocalización, intente nuevamente!')
        return
      }

      this.conservacionGateway.usuarioCreacion = {
        id: this.usuario.id,
        nombre: `${this.usuario.firstName} ${this.usuario.firstSurname}`,
        name: `${this.usuario.firstName} ${this.usuario.firstSurname}`,
        email: this.usuario.email,
        telefono: '',
      }

      const body = { ...this.conservacionGateway }
      body.supresor = this.conservacionGateway.supresor !== null ? this.conservacionGateway.supresor.toString() : null
      body.especificacion = this.conservacionGateway.especificacion !== null ? this.conservacionGateway.especificacion.toString() : null
      let item

      if (this.$route.query.action === 'Create') {
        const newItem = await updateCreateGateway(body, 2)
        item = { ...newItem }
      } else if (this.$route.query.action === 'Edit') {
        item = body
      }

      const urlBucket = `conservacion-gateway/${item.id}`
      const isMobile = window.screen.width <= 760

      if (!item.seguimiento) {
        item.seguimiento = []
      }

      const imagen1 = await saveImagen(urlBucket, this.localization, this.fotoPanoramicaAntes, 'Foto Panorámica Antes', '', this.conservacionGateway.usuario, isMobile)
      const imagen2 = await saveImagen(urlBucket, this.localization, this.fotoPanoramicaDespues, 'Foto Panorámica Después', '', this.conservacionGateway.usuario, isMobile)
      if (this.$route.query.action === 'Create') {
        if (imagen1) item.seguimiento.push(imagen1)
        if (imagen2) item.seguimiento.push(imagen2)
      } else {
        if (imagen1) item.seguimiento[0] = imagen1
        if (imagen2) item.seguimiento[1] = imagen2
      }
      await updateCreateGateway(item, 1)

      mensajeInformativo('Guardada', 'Conservación de Gateway Almacenada Perfectamente!')
      this.$router.replace('/conservacion-gateway').then(() => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Conservación Gateway Almacenada!',
            icon: 'CheckIcon',
            variant: 'success',
            text: 'Conservación de Gateway Ingresada Correctamente!',
          },
        })
      })
      this.show = false
    },
    handleTrabajosGatewayChange() {
      const removedOptions = this.trabajosGateway.filter(
        originalOption => !this.conservacionGateway.trabajosGateway.some(
          newOption => newOption.id === originalOption.id,
        ),
      )

      removedOptions.forEach(option => {
        if (option.id === 4) {
          this.conservacionGateway.especificacion = ''
        } else if (option.id === 6) {
          this.conservacionGateway.supresor = ''
        }
      })
    },
    checkAccessMode() {
      const { action } = this.$route.query
      this.editable = action === 'Edit'

      if (!this.editable) {
        this.hideAdditionalColumn()
      }
    },
    hideAdditionalColumn() {
      this.showAdditionalColumn = false
    },
    changeEditable() {
      router.currentRoute.params.action = 'Edit'
      this.editable = false
    },
    getTextButton() {
      if (router.currentRoute.params.action === 'Edit') return 'Actualizar'
      return 'Guardar'
    },
    cancel() {
      const { action } = this.$route.query
      if (action === 'Edit' || action === 'Create'
      ) this.$router.push({ path: '/conservacion-gateway' })
    },

    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.guardarGateway()
        }
      })
    },
    fotografiaPanoramicaAntes(file) {
      this.fotoPanoramicaAntes = file
    },
    fotografiaPanoramicaDespues(file) {
      this.fotoPanoramicaDespues = file
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
